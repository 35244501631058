<script>
	import '$lib/app.postcss';

	import Livechat from '$lib/Components/LiveChat/LiveChat.svelte';
	import PageMeta from '$lib/Components/PageMeta/PageMeta.svelte';
	import Title from '$lib/Components/PageTitle/PageTitle.svelte';
	import Pixels from '$lib/Components/Pixels/Pixels.svelte';
	// import { webVitals } from '$lib/Components/Vercel/SpeedInsights/SpeedInsights.js';
	import Footer from '$lib/PageSections/Footer/Footer.svelte';
	import Header from '$lib/PageSections/Header/Header.svelte';
	import { setContext } from 'svelte';

	import { Toaster } from 'svelte-french-toast';
	import { writable } from 'svelte/store';

	setContext('sessionData', { user: writable({}) });

	// import { env } from '$env/dynamic/public';
	// const BROWSER = typeof window !== 'undefined';

	// let inject;
	// async function injectVercelAnalytics() {
	// 	inject = await (await import('@vercel/analytics')).inject;
	// 	inject({
	// 		mode: 'production',
	// 	});
	// }
	// if (BROWSER && env.VERCEL_ANALYTICS_ID) {
	// 	injectVercelAnalytics();
	// }

	// afterNavigate(() => {
	// 	if (BROWSER && env.VERCEL_ANALYTICS_ID) {
	// 		webVitals({
	// 			analyticsId: env.VERCEL_ANALYTICS_ID,
	// 		});
	// 	}
	// });
</script>

<PageMeta />
<Toaster />
<Header />
<main class="content-grid">
	<Title />
	<slot />
</main>
<Footer />

<Livechat />

<Pixels />

<style>
	main {
		height: inherit;
	}
</style>
